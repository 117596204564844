import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import { TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Editor from 'Components/trainer/measurements/Editor';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import { TAB_MEASUREMENTS } from 'Components/pages/admin/AdeptData/component';

export const TAB_DATA = 'data';

export default class TrainerMeasurementsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const queryObject = parseQueryToObject(location.search);
        
        return (
            <StyledComponent
                className="trainer-measurements-create"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Dodaj nowy pomiar"
                        controls={[{
                            visible: true,
                            key: 'return',
                            label: 'Powrót do pomiarów',
                            onClick: () => history.push(
                                withVariables(TRAINER_ADEPTS_MANAGE.path, { id: queryObject.userId }, { tab: TAB_MEASUREMENTS }),
                            ),
                            style: 'gradient',
                            layout: 'wide',
                        }]}
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane',
                            children: (
                                <Editor
                                    location={location}
                                    history={history}
                                    predefinedState={{
                                        userId: queryObject.userId,
                                    }}
                                />
                            ),
                        }]}
                    />

                </PageContent>
            </StyledComponent>
        );
    }
}